@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;900&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #fff;
}

* {
  font-family: 'Montserrat', sans-serif;
}

h2,
h3,
p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

div ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: inherit;
}

div ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #8c38d5;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulse-more {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
  }
}
